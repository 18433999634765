import React from "react";
import Draggable from "react-draggable";
import "./ContactBox.css";
import qrCodeImage from "./lianxierweima.png";

const ContactBox = () => {
  return (
    <Draggable bounds="body">
      <div className="contact-box">
        <img src={qrCodeImage} alt="QR Code" />
        <div className="contactinfo">
        <p>电话 +86 139 2428 6456</p>
        <p>微信 zyx913374658</p>
        </div>
      </div>
    </Draggable>
  );
};

export default ContactBox;
