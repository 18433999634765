import React from "react";
import { Link } from "react-router-dom";
import "./Head.css";
import logo from "../photos/logo.png";
import headerb from "../photos/headerb.png";
import headerc from "../photos/headerc.png";

const Head = () => {
  return (
    <div className="head">
      <div className="headcontainer">
        <Link to="/">
          <div className="logo">
            <img className="logoimg" src={logo} alt="亨通药业" />
          </div>
        </Link>
        <div style={{ flexDirection: `row` }}>
          <img className="headimg" src={headerb} alt="" />
          <img className="headimg" src={headerc} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Head;
