import React from "react";
import "./Terms.css";
import ContactBox from "../home/ContactBox";
import dizhi from "../photos/dizhi.png";
import yiyaogang from "../photos/yiyaogang.jpeg";
import yiyaogang2 from "../photos/yiyaogang2.jpeg";
import yiyaogang3 from "../photos/yiyaogang3.jpeg";
import menmian from "../photos/门面照片/menmian.jpeg";
import menmian1 from "../photos/门面照片/KakaoTalk_Image_2024-02-03-11-25-59_021.jpeg";
import menmian2 from "../photos/门面照片/KakaoTalk_Image_2024-02-03-11-26-00_025.jpeg";
import yingye from "../photos/yingye.jpeg";
import shipin from "../photos/shipin.jpeg";

export default function Terms({ header }) {
  let content;

  switch (header) {
    case "使用条款":
      content = (
        <>
          <h2>欢迎访问恒通药业网站 - hengtongyaoye.com</h2>

          <p>
            感谢您访问恒通药业网站。请在使用本网站之前仔细阅读以下使用条款。通过使用本网站，即表示您同意遵守这些条款。如果您不同意以下任何条款，请不要使用本网站。
          </p>

          <h3>1. 网站目的</h3>
          <p>
            本网站旨在提供关于我们公司及其产品的信息，以及便于与您联系。网站上的所有内容仅供参考，恒通药业保留随时更改网站内容的权利。
          </p>

          <h3>2. 信息准确性</h3>
          <p>
            我们努力确保网站上的信息准确无误。然而，恒通药业不对网站上的任何信息的准确性、完整性或实用性承担责任。我们保留更正或更新网站上的任何信息的权利。
          </p>

          <h3>3. 链接至第三方网站</h3>
          <p>
            本网站可能包含指向第三方网站的链接，这些链接仅为方便访问而提供。恒通药业对这些第三方网站的内容不承担责任，也不对其准确性负责。
          </p>

          <h3>4. 联系信息</h3>
          <p>
            如有任何疑问或意见，您可以通过以下联系方式与我们取得联系：
            <br />
            公司名称：广州亨通药业有限公司
            <br />
            地址：中国广州市荔湾区医药港南中街2555--2556档
            <br />
            电话：13924286456
          </p>

          <p>
            请注意，本使用条款可能会随时更改。请定期查看以确保您了解任何变更。谢谢您访问我们的网站！
          </p>
          <ContactBox />
        </>
      );
      break;
    case "联系我们":
      content = (
        <>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-BQK8HE7P1G"
          ></script>
          <script>
            {function () {
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                window.dataLayer.push(arguments);
              }
              gtag("js", new Date());
              gtag("config", "G-BQK8HE7P1G");
            }()}
          </script>
          <p className="terms-text">
            中国 广州市荔湾区医药港南中街 2555--2556档
          </p>
          <p className="terms-text">电话 13924286456</p>
          <p className="terms-text">微信 zyx913374658</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={dizhi}
              style={{ maxWidth: "100%", height: "auto", margin: 30 }}
            />
          </div>
          <ContactBox />
        </>
      );
      break;

    case "公司简介":
      content = (
        <>
          <p className="terms-text">
            广州亨通药业有限公司成立于2020年5月22日，总部位于广州市国际医药港。作为中国传统药材的集散地，我们专注于经营中药材贸易，主要涉及人参、红参、鹿茸等东北特产品的零售和批发业务。
            公司创始人祖燕侠是一位农民之女，她的父亲终生致力于在中国吉林省集安市种植人参，并为公司提供了优质的原材料。
            广州亨通药业有限公司以广州市中医药港为据点，积极传承中华传统药材文化，致力于为客户提供高品质的中药材产品。
            我们的使命是在中医药港这个理想的位置上，通过不懈努力和持续创新，为客户提供多样化的选择，促进人们的健康和福祉。
            我们期待通过在中药材领域的发展，为中医药产业的繁荣作出积极的贡献。
          </p>
          <p className="terms-text">广州市国际医药港</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={yiyaogang}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
            <img
              src={yiyaogang2}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
            <img
              src={yiyaogang3}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
          </div>
          <p className="terms-text">广州亨通药业</p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={menmian}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
            <img
              src={menmian1}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
            <img
              src={menmian2}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={yingye}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
            <img
              src={shipin}
              style={{ maxWidth: "30%", height: "auto", margin: 10 }}
            />
          </div>
          <ContactBox />
        </>
      );
      break;
    default:
      content = <p className="terms-text">Default content goes here.</p>;
  }

  return (
    <div>
      <h2 className="terms-header">{header}</h2>
      {content}
    </div>
  );
}
