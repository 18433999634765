
import shanshen1 from "./photos/精品山参/shanshen1.jpeg"
import shanshen2 from "./photos/精品山参/shanshen2.jpeg"
import shanshen3 from "./photos/精品山参/shanshen3.jpeg"
import shanshen4 from "./photos/精品山参/shanshen4.jpeg"
import shanshen5 from "./photos/精品山参/shanshen5.jpeg"

import hongshen from "./photos/红参/hongshen.jpeg"
import hongshen1 from "./photos/红参/hongshen1.jpeg"
import hongshen2 from "./photos/红参/hongshen2.jpeg"
import hongshen3 from "./photos/红参/hongshen3.jpeg"

import lihe1 from "./photos/礼盒/礼盒1林下参/lihe1-1.jpeg"
import lihe1_1 from "./photos/礼盒/礼盒1林下参/lihe1.jpeg"
import lihe2 from "./photos/礼盒/礼盒2红色山参简装/lihe2.jpeg"
import lihe2_1 from "./photos/礼盒/礼盒2红色山参简装/lihe2-1.jpeg"
import lihe4 from "./photos/礼盒/礼盒4款款情有你/lihe4.jpeg"
import lihe4_1 from "./photos/礼盒/礼盒4款款情有你/lihe4-1.jpeg"
import lihe4_2 from "./photos/礼盒/礼盒4款款情有你/lihe4-3.jpeg"
import lihe4_3 from "./photos/礼盒/礼盒4款款情有你/lihe4-2.jpeg"
import lihe3 from "./photos/礼盒/礼盒3天赐珍宝/lihe3.jpeg"
import lihe3_1 from "./photos/礼盒/礼盒3天赐珍宝/lihe3-1.jpeg"
import lihe3_2 from "./photos/礼盒/礼盒3天赐珍宝/lihe3-2.jpeg"
import lihe5 from "./photos/礼盒/礼盒5褐色野山参/lihe5.jpeg"
import lihe5_1 from "./photos/礼盒/礼盒5褐色野山参/lihe5-1.jpeg"
import lihe5_2 from "./photos/礼盒/礼盒5褐色野山参/lihe5-2.jpeg"
import lihe6 from "./photos/礼盒/礼盒6红色野山参/lihe6.jpeg"
import lihe6_1 from "./photos/礼盒/礼盒6红色野山参/lihe6-1.jpeg"
import lihe7 from "./photos/礼盒/礼盒7吉祥/lihe1.jpeg"

import pihuo1 from "./photos/批货/pihuo1.jpeg"
import pihuo2 from "./photos/批货/pihuo2.jpeg"
import pihuo3 from "./photos/批货/pihuo3.jpeg"
import pihuo4 from "./photos/批货/pihuo4.jpeg"
import pihuo5 from "./photos/批货/pihuo5.jpeg"
import pihuo6 from "./photos/批货/pihuo6.jpeg"
import pihuo7 from "./photos/批货/pihuo7.jpeg"

import lurong from "./photos/鹿茸/lurong.jpeg"
import lurong2 from "./photos/鹿茸/lurong2.jpeg"
import lurong3 from "./photos/鹿茸/lurong5.jpeg"

import hama2 from "./photos/蛤蟆/hamayou1.jpeg"
import hama3 from "./photos/蛤蟆/hamayou3.jpeg"
import hama4 from "./photos/蛤蟆/hamayou2.jpeg"


const data = [
  {
    id: 0,
    header: "精品野山参",
    image: shanshen1,
    price: 100,
    amount: 0,
    description: "野山参也叫林下参，在林地里生长15年以上的就叫野山参，人工把种子撒播在山林里自然生长15年以上。山参皂苷含量高，含有丰富的人体所需微量元素。参味回甘带甜，体抛轻盈，无药水，无硫磺，不接胶，自然烘干。山参的功效是大补元气，补脾益肺，生津安神。提高人体的免疫力。尤其对这次中过阳的人，补肺气果特别好，不宜与藜芦同时服用。 高端野生人参礼盒，通过国家实验室验证，内含八爪鱼形状的珍贵野生人参，别具匠心地镶嵌在红色盘子上。这份独特的人参礼盒融合了传统草药的珍贵之处，是一份珍贵而有品位的礼物。"
  },
  {
    id: 1,
    header: "精品野山参",
    image: shanshen2,
    price: 1000,
    amount: 0,
    description: "野山参也叫林下参，在林地里生长15年以上的就叫野山参，人工把种子撒播在山林里自然生长15年以上。山参皂苷含量高，含有丰富的人体所需微量元素。参味回甘带甜，体抛轻盈，无药水，无硫磺，不接胶，自然烘干。山参的功效是大补元气，补脾益肺，生津安神。提高人体的免疫力。尤其对这次中过阳的人，补肺气果特别好，不宜与藜芦同时服用。 高端野生人参礼盒，通过国家实验室验证，内含八爪鱼形状的珍贵野生人参，别具匠心地镶嵌在红色盘子上。这份独特的人参礼盒融合了传统草药的珍贵之处，是一份珍贵而有品位的礼物。"
  },
  {
    id: 2,
    header: "精品野山参",
    image: shanshen3,
    price: 1000,
    amount: 0,
    description: "野山参也叫林下参，在林地里生长15年以上的就叫野山参，人工把种子撒播在山林里自然生长15年以上。山参皂苷含量高，含有丰富的人体所需微量元素。参味回甘带甜，体抛轻盈，无药水，无硫磺，不接胶，自然烘干。山参的功效是大补元气，补脾益肺，生津安神。提高人体的免疫力。尤其对这次中过阳的人，补肺气果特别好，不宜与藜芦同时服用。 高端野生人参礼盒，通过国家实验室验证，内含八爪鱼形状的珍贵野生人参，别具匠心地镶嵌在红色盘子上。这份独特的人参礼盒融合了传统草药的珍贵之处，是一份珍贵而有品位的礼物。"
  },
  {
    id: 3,
    header: "精品野山参",
    image: shanshen4,
    price: 1000,
    amount: 0,
    description: "野山参也叫林下参，在林地里生长15年以上的就叫野山参，人工把种子撒播在山林里自然生长15年以上。山参皂苷含量高，含有丰富的人体所需微量元素。参味回甘带甜，体抛轻盈，无药水，无硫磺，不接胶，自然烘干。山参的功效是大补元气，补脾益肺，生津安神。提高人体的免疫力。尤其对这次中过阳的人，补肺气果特别好，不宜与藜芦同时服用。 高端野生人参礼盒，通过国家实验室验证，内含八爪鱼形状的珍贵野生人参，别具匠心地镶嵌在红色盘子上。这份独特的人参礼盒融合了传统草药的珍贵之处，是一份珍贵而有品位的礼物。"
  },
  {
    id: 4,
    header: "精品野山参",
    image: shanshen5,
    price: 1000,
    amount: 0,
    description: "野山参也叫林下参，在林地里生长15年以上的就叫野山参，人工把种子撒播在山林里自然生长15年以上。山参皂苷含量高，含有丰富的人体所需微量元素。参味回甘带甜，体抛轻盈，无药水，无硫磺，不接胶，自然烘干。山参的功效是大补元气，补脾益肺，生津安神。提高人体的免疫力。尤其对这次中过阳的人，补肺气果特别好，不宜与藜芦同时服用。 高端野生人参礼盒，通过国家实验室验证，内含八爪鱼形状的珍贵野生人参，别具匠心地镶嵌在红色盘子上。这份独特的人参礼盒融合了传统草药的珍贵之处，是一份珍贵而有品位的礼物。"
  },
  {
    id: 5,
    header: "精品林下山参礼盒",
    image: lihe1_1,
    image2: lihe1,
    price: 1000,
    amount: 0,
    description: "林下参包检无农残，林下参其生长方式环境及人工干预的程度决定参质地的优劣，身上有锈和疤痕都属于正常现象，因为在生长过程中没有摄入太多干预导致品相没有卖点，但一点不影响它的作用和含量。"
  },
  {
    id: 6,
    header: "山参礼盒",
    image: lihe2,
    image2: lihe2_1,
    price: 200,
    amount: 0,
    description: "野山参也叫林下参，在林地里生长15年以上的就叫野山参，人工把种子撒播在山林里自然生长15年以上。山参皂苷含量高，含有丰富的人体所需微量元素。参味回甘带甜，体抛轻盈，无药水，无硫磺，不接胶，自然烘干。山参的功效是大补元气，补脾益肺，生津安神。提高人体的免疫力。尤其对这次中过阳的人，补肺气果特别好，不宜与藜芦同时服用。 高端野生人参礼盒，通过国家实验室验证，内含八爪鱼形状的珍贵野生人参，别具匠心地镶嵌在红色盘子上。这份独特的人参礼盒融合了传统草药的珍贵之处，是一份珍贵而有品位的礼物。"
  },
  {
    id: 7,
    header: "款款参情鹿有你礼盒",
    image: lihe4_1,
    image2: lihe4,
    image3: lihe4_2,
    image4: lihe4_3,
    price: 200,
    amount: 0,
    description: "精选上等原产地野生人参、人参切片和鹿茸切片，打造这款独特的礼品套装。这一套礼品集结了大自然的珍贵馈赠，为您的健康和福祉提供了卓越的支持。 野生人参具有卓越的滋补功效，帮助提高体力和抵抗力。人参切片精心制作，保留了人参的纯正精华，为您带来焕发活力的感觉。而鹿茸切片富含多种天然营养成分，有助于促进血液循环和提升免疫力。 这个礼品套装不仅呈现了传统的中草药之美，还彰显了对身体健康的关怀。送给亲朋好友，是表达关爱和祝福的绝佳选择。愿这份珍贵的礼物为您和您所爱的人带来健康和幸福。"
  },
  {
    id: 8,
    header: "天赐珍宝野山参礼盒",
    image: lihe3,
    image2: lihe3_1,
    image3: lihe3_2,
    price: 200,
    amount: 0,
    description: "天赐珍宝野山参礼盒，汇聚了岁月沉淀的稀世老山参，为您奉献一份极致珍贵的滋补礼遇。这个礼盒以精选的老山参为灵魂，为您呈现了大自然的馈赠和岁月的沉淀。这款礼盒中的老山参经过精心挑选，质地饱满，味道浓郁。其滋补功效被誉为提升体力、改善免疫力的极品之选。每一片老山参都承载着大自然的恩赐，传递着岁月的沧桑之美。天赐珍宝野山参礼盒不仅是对品质生活的追求，更是对身体健康的呵护。送礼或珍藏，都是彰显尊贵品位的绝佳选择。愿这份天赐之宝为您带来健康长寿，成为您美好生活的陪伴。"
  },
  {
    id: 9,
    header: "野山参礼盒",
    image: lihe5_1,
    image2: lihe5,
    image3: lihe5_2,
    price: 200,
    amount: 0,
    description: "精品野山参礼盒"
  },
  {
    id: 10,
    header: "野山参礼盒",
    image: lihe6,
    image2: lihe6_1,
    price: 200,
    amount: 0,
    description: "精品野山参礼盒"
  },
  {
    id: 11,
    header: "吉祥如意野山参礼盒",
    image: lihe7,
    price: 200,
    amount: 0,
    description: "吉祥如意野山参礼盒，集结了精选的野山参、鹿鞭片、灵芝和鹿茸片，为您带来一份吉祥如意的养生礼遇。这个礼盒融合了多种珍贵中草药，致力于为您的身体和心灵带来全方位的滋补。精品野山参以其独特的滋补效果而闻名，有助于提升体力和促进身体平衡。鹿鞭片被誉为强身健体的珍品，为您注入活力和耐力。灵芝作为天然的免疫调节剂，有助于增强身体的抵抗力。而鹿茸片则富含多种营养成分，促进血液循环和提升能量水平。吉祥如意野山参礼盒不仅是对健康的呵护，更是一份寓意吉祥如意的美好礼物。送给亲朋好友，表达对他们的关怀与祝福。让这份吉祥礼盒成为您生活中的守护良伴，为您带来健康和幸福。"
  },
  {
    id: 12,
    header: "鹿茸片",
    image: lurong,
    image3: lurong2,
    image4: lurong3,
    price: 200,
    amount: 0,
    description: "鹿茸，作为一种传统而珍贵的中草药，被誉为补虚壮阳的极品之一。这款鹿茸产品以新鲜、纯正的鹿茸为原料，经过精心提取和加工，为您呈现天然的滋补和养生福祉。鹿茸的珍贵成分使其成为中医养生的经典之选。鹿茸富含丰富的生物活性成分，包括多种氨基酸、矿物质和生长因子，有助于促进血液循环、提高免疫力和改善体质。其独特的滋补效果在传统中医养生中一直备受推崇，被认为能够调和阴阳、补益元气。这款鹿茸产品是对身体强壮和健康的极致呵护，适合需要提高体力、改善免疫系统功能的人群。在日常养生中，鹿茸被视为珍贵的养生佳品，为您的身体注入源源不断的活力。选择鹿茸，选择身体健康和养生的高品质生活。"
  },
  {
    id: 13,
    header: "东北雪蛤蟆",
    image: hama2,
    image2: hama3,
    image3: hama4,
    price: 200,
    amount: 0,
    description: "东北雪蛤蟆蛤蟆油，是一款珍贵的养生保健产品，以东北雪蛤蟆为主要原料，提取纯正蛤蟆油，为您带来天然的滋补和保健功效。这款产品汇聚了大自然的珍贵滋养，旨在为您的健康提供全方位的关爱。东北雪蛤蟆被誉为滋阴养颜的珍贵食材，含有丰富的蛋白质和多种营养成分，有助于美容养颜和改善体质。蛤蟆油是从雪蛤蟆中提炼而来，富含天然的脂肪酸和矿物质，对于调理免疫系统和促进血液循环具有显著的效果。这款东北雪蛤蟆蛤蟆油不仅是滋补养生的理想之选，也是对自己和亲人的健康关怀。无论是作为日常保健还是送礼佳品，都能为您带来滋养身心的美好体验。让东北雪蛤蟆蛤蟆油成为您健康生活的贴心伴侣，享受自然养生的纯正滋味。"
  },
  {
    id: 14,
    header: "红参批发",
    image: hongshen1,
    price: 200,
    amount: 0,
    description: "红参，作为珍贵的中草药之一，是经过特殊处理和蒸制而成的高品质人参。其独特的制作过程使其具有红艳的外观和更浓郁的滋补功效。这款红参产品汇聚了大自然的精华，为您提供卓越的滋补和养生体验。红参被誉为滋补强壮的佳品，其含有丰富的人参皂苷和多种微量元素，有助于提高体力、增强免疫力并促进身体的整体平衡。其独特的风味和口感也使其成为深受喜爱的养生佳品。这款红参产品是对身体健康的贴心关照，是日常保健的理想之选。不论是繁忙的生活还是追求品质养生，红参都是您追求健康和活力的不二之选。品味红参的独特魅力，让它成为您日常养生路上的珍贵伴侣。 精制红参， 散装， 礼盒， 红参片， 按大小规格不同价位。"
  },
  {
    id: 15,
    header: "山参散装批发",
    image: pihuo3,
    image2: pihuo5,
    image3: pihuo7,
    price: 200,
    amount: 0,
    description: "山参散装批货，是专为经销商和制造商提供的大宗养生原料。这款散装山参以其高品质和多功能性而脱颖而出，为广泛的客户群体提供了卓越的养生选择。无论您是批发商、制药厂商还是养生产品生产商，这款山参散装批货都是您养生产品线的理想补充。山参作为传统中草药的代表，具有卓越的滋补功效。我们提供的散装山参可满足各类养生产品的生产需要，包括药品、保健品和营养补充剂等。产品质量可靠，成分纯正，适用于不同领域的生产加工。散装批货的优势在于供应灵活性和定价协商的空间。我们理解不同客户的需求各异，因此提供量身定制的服务，以满足您的特殊要求。无论您是寻找高品质原料还是打造自有品牌的养生产品，山参散装批货都是您可信赖的合作伙伴。选择山参散装批货，助您在市场竞争中脱颖而出。无论您是养生产品的制造商还是经销商，我们将为您提供稳定可靠的供应，共同携手打造充满活力的养生市场。"

  },
  {
    id: 16,
    header: "山参礼盒批发",
    image: pihuo1,
    price: 200,
    amount: 0,
    description: "山参礼盒批货，不仅是一款高品质的养生礼品套装，更是可根据客户需求定制的灵活选择。其独特之处在于，我们提供个性化定制服务，以满足不同客户的特殊要求。价格可根据定制内容进行协商，确保您获得最具竞争力的批发价格。这款批量交易的山参礼盒，适用于各类企业或组织，是送给员工、合作伙伴或客户的理想礼物。其个性化定制的特性使其成为展现企业关怀的完美选择。通过定制礼盒，您可以将企业的品牌元素、标志或个性化祝福融入其中，为您的员工或合作伙伴提供一份独特而有意义的礼物。 这款礼盒不仅是对身体健康的关怀，更是传递了对员工辛勤工作的感激之情。无论是企业庆典、员工生日，或是节假日赠礼，山参礼盒批货都是展现慷慨与关怀的完美选择。选择我们的定制服务，打造独特的企业礼物，为您的品牌增色添彩。"
  },
  {
    id: 17,
    header: "山参礼盒批发",
    image: pihuo2,
    price: 200,
    amount: 0,
    description: "山参礼盒批货，不仅是一款高品质的养生礼品套装，更是可根据客户需求定制的灵活选择。其独特之处在于，我们提供个性化定制服务，以满足不同客户的特殊要求。价格可根据定制内容进行协商，确保您获得最具竞争力的批发价格。这款批量交易的山参礼盒，适用于各类企业或组织，是送给员工、合作伙伴或客户的理想礼物。其个性化定制的特性使其成为展现企业关怀的完美选择。通过定制礼盒，您可以将企业的品牌元素、标志或个性化祝福融入其中，为您的员工或合作伙伴提供一份独特而有意义的礼物。 这款礼盒不仅是对身体健康的关怀，更是传递了对员工辛勤工作的感激之情。无论是企业庆典、员工生日，或是节假日赠礼，山参礼盒批货都是展现慷慨与关怀的完美选择。选择我们的定制服务，打造独特的企业礼物，为您的品牌增色添彩。"
  },
  {
    id: 18,
    header: "山参礼盒批发",
    image: pihuo6,
    price: 200,
    amount: 0,
    description: "山参礼盒批货，不仅是一款高品质的养生礼品套装，更是可根据客户需求定制的灵活选择。其独特之处在于，我们提供个性化定制服务，以满足不同客户的特殊要求。价格可根据定制内容进行协商，确保您获得最具竞争力的批发价格。这款批量交易的山参礼盒，适用于各类企业或组织，是送给员工、合作伙伴或客户的理想礼物。其个性化定制的特性使其成为展现企业关怀的完美选择。通过定制礼盒，您可以将企业的品牌元素、标志或个性化祝福融入其中，为您的员工或合作伙伴提供一份独特而有意义的礼物。 这款礼盒不仅是对身体健康的关怀，更是传递了对员工辛勤工作的感激之情。无论是企业庆典、员工生日，或是节假日赠礼，山参礼盒批货都是展现慷慨与关怀的完美选择。选择我们的定制服务，打造独特的企业礼物，为您的品牌增色添彩。"
  },
  {
    id: 19,
    header: "山参礼盒批发",
    image: pihuo4,
    price: 200,
    amount: 0,
    description: "山参礼盒批货，不仅是一款高品质的养生礼品套装，更是可根据客户需求定制的灵活选择。其独特之处在于，我们提供个性化定制服务，以满足不同客户的特殊要求。价格可根据定制内容进行协商，确保您获得最具竞争力的批发价格。这款批量交易的山参礼盒，适用于各类企业或组织，是送给员工、合作伙伴或客户的理想礼物。其个性化定制的特性使其成为展现企业关怀的完美选择。通过定制礼盒，您可以将企业的品牌元素、标志或个性化祝福融入其中，为您的员工或合作伙伴提供一份独特而有意义的礼物。 这款礼盒不仅是对身体健康的关怀，更是传递了对员工辛勤工作的感激之情。无论是企业庆典、员工生日，或是节假日赠礼，山参礼盒批货都是展现慷慨与关怀的完美选择。选择我们的定制服务，打造独特的企业礼物，为您的品牌增色添彩。"
  },
  {
    id: 20,
    header: "红参片",
    image: hongshen2,
    price: 200,
    amount: 0,
    description: "红参，作为珍贵的中草药之一，是经过特殊处理和蒸制而成的高品质人参。其独特的制作过程使其具有红艳的外观和更浓郁的滋补功效。这款红参产品汇聚了大自然的精华，为您提供卓越的滋补和养生体验。红参被誉为滋补强壮的佳品，其含有丰富的人参皂苷和多种微量元素，有助于提高体力、增强免疫力并促进身体的整体平衡。其独特的风味和口感也使其成为深受喜爱的养生佳品。这款红参产品是对身体健康的贴心关照，是日常保健的理想之选。不论是繁忙的生活还是追求品质养生，红参都是您追求健康和活力的不二之选。品味红参的独特魅力，让它成为您日常养生路上的珍贵伴侣。 精制红参， 散装， 礼盒， 红参片， 按大小规格不同价位。"
  },
  {
    id: 21,
    header: "红参礼盒",
    image: hongshen,
    image2: hongshen3,
    price: 200,
    amount: 0,
    description: "红参，作为珍贵的中草药之一，是经过特殊处理和蒸制而成的高品质人参。其独特的制作过程使其具有红艳的外观和更浓郁的滋补功效。这款红参产品汇聚了大自然的精华，为您提供卓越的滋补和养生体验。红参被誉为滋补强壮的佳品，其含有丰富的人参皂苷和多种微量元素，有助于提高体力、增强免疫力并促进身体的整体平衡。其独特的风味和口感也使其成为深受喜爱的养生佳品。这款红参产品是对身体健康的贴心关照，是日常保健的理想之选。不论是繁忙的生活还是追求品质养生，红参都是您追求健康和活力的不二之选。品味红参的独特魅力，让它成为您日常养生路上的珍贵伴侣。 精制红参， 散装， 礼盒， 红参片， 按大小规格不同价位。"
  },
];

export default data;
