import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import data from "./data";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Head from "./header/Head";
import NewProduct from "./products/NewProduct";
import shanshen1 from "./photos/精品山参/shanshen1.jpeg";
import lihe from "./photos/礼盒/礼盒3天赐珍宝/lihe3-1.jpeg";
import lihe2 from "./photos/礼盒/礼盒4款款情有你/lihe4-2.jpeg";
import menmian from "./photos/门面照片/menmianslide.jpeg";
import FilteredProducts from "./products/FilteredProducts";
import Terms from "./footer/Terms";
import "./App.css";

export const Context = React.createContext();

const App = () => {
  const [cartItems, setCartItems] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("cartItems");
    const initialValue = JSON.parse(saved);
    if (!initialValue) {
      localStorage.setItem("cartItems", JSON.stringify(0));
    }
    return initialValue;
  });

  const slides = [
    { url: shanshen1, title: "shanshen1" },
    { url: lihe, title: "lihe" },
    { url: lihe2, title: "lihe2" },
    { url: menmian, title: "memmian" },
  ];

  const [products, setProducts] = useState(() => {
    const saved = localStorage.getItem("product");
    const initialValue = JSON.parse(saved);
    if (!initialValue) {
      localStorage.setItem("product", JSON.stringify([]));
    }
    return initialValue || [];
  });

  const Home = lazy(() => import("./home/Home"));

  const renderProductRoutes = () => {
    return data.map((_, index) => (
      <Route
        key={`product-route-${index}`}
        exact
        path={`/product/id-${index}`}
        element={<NewProduct index={index} />}
      />
    ));
  };

  return (
      <Router>
        <Context.Provider
          value={{ cartItems, setCartItems, products, setProducts, data }}
        >
          <Head />
          <Header />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home slides={slides} />} />
              {renderProductRoutes()}
              <Route
                exact
                path="/shanshen"
                element={<FilteredProducts product={"山参"} />}
              />
              <Route
                exact
                path="/hongshen"
                element={<FilteredProducts product={"红参"} />}
              />
              <Route
                exact
                path="/lihe"
                element={<FilteredProducts product={"礼盒"} />}
              />
              <Route
                exact
                path="/pifa"
                element={<FilteredProducts product={"批发"} />}
              />
              <Route
                exact
                path="/lurong"
                element={<FilteredProducts product={"鹿茸"} />}
              />
              <Route
                exact
                path="/hama"
                element={<FilteredProducts product={"蛤蟆"} />}
              />
              <Route
                exact
                path="/terms-and-conditions"
                element={<Terms header={"使用条款"} />}
              />
              <Route
                exact
                path="/contact"
                element={<Terms header={"联系我们"} />}
              />
              <Route
                exact
                path="/about-us"
                element={<Terms header={"公司简介"} />}
              />
            </Routes>
          </Suspense>
          <Footer />
        </Context.Provider>
      </Router>
  );
};

export default App;
