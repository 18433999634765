// import iphone2 from "./iphone-2.jpg";
import React, { useContext, useState } from "react";
import "./Product.css";
import { Context } from "../App";
import ContactBox from "../home/ContactBox";

const NewProduct = ({ index }) => {
  const value = useContext(Context);
  const header = value.data[index].header;
  const price = value.data[index].price;
  const image = value.data[index].image;
  const image2 = value.data[index].image2;
  const image3 = value.data[index].image3;
  const image4 = value.data[index].image4;

  const description = value.data[index].description;

  const [selected, setSelected] = useState(image);
  const [zoomedImage, setZoomedImage] = useState(false);

  return (
    <div className="product-page">
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-BQK8HE7P1G"
      ></script>
      <script>
        {function () {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-BQK8HE7P1G");
        }()}
      </script>
      <h2 className="product-header">{header}</h2>
      <div className="product-page__container">
        <div className="product__column__small">
          <img
            src={image}
            alt="shanshen"
            className={`product-image-small ${
              selected === image ? "selected-image" : ""
            }`}
            onClick={() => setSelected(image)}
          ></img>

          {image2 && (
            <img
              src={image2}
              alt="shanshen"
              className={`product-image-small ${
                selected === image2 ? "selected-image" : ""
              }`}
              onClick={() => setSelected(image2)}
            ></img>
          )}

          {image3 && (
            <img
              src={image3}
              alt="shanshen"
              className={`product-image-small ${
                selected === image3 ? "selected-image" : ""
              }`}
              onClick={() => setSelected(image3)}
            ></img>
          )}

          {image4 && (
            <img
              src={image4}
              alt="shanshen"
              className={`product-image-small ${
                selected === image4 ? "selected-image" : ""
              }`}
              onClick={() => setSelected(image4)}
            ></img>
          )}
        </div>
        <div className="product-page__container__column_photo">
          <img
            src={selected}
            alt="山参"
            className={`product-image ${zoomedImage ? "zoomed-image" : ""}`}
          ></img>
          <div className="buttons-container">
            <button
              className="zoom-button"
              onClick={() => setZoomedImage(true)}
            >
              +
            </button>
            <button
              className="zoom-button"
              onClick={() => setZoomedImage(false)}
            >
              -
            </button>
          </div>
        </div>
      </div>
      {/* <h2 className="price-tag">价格: {price}元</h2> */}
      <div className="product-page__container__column">
        <h2>商品简介:</h2>
        <h3> {description} </h3>
      </div>
      <ContactBox />
    </div>
  );
};
export default NewProduct;
