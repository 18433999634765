import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-list">
        <a href="/terms-and-conditions" className="footer-list__item">
          使用条款
        </a>
        <a href="/contact" className="footer-list__item">
          联系我们
        </a>

        <a href="/about-us" className="footer-list__item">
          公司简介
        </a>
      </div>
    </div>
  );
};
export default Footer;
