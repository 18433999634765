import "./Header.css";
// import cart from "./cart.jpg";
import React from "react";
import { Context } from "../App";
import cartImage from "../photos/cart.jpg";

const Header = () => {
  const value = React.useContext(Context);
  return (
    <div className="header">
      <div className="header-list">
        <a href="/" className="header-list__item">
          主页
        </a>
        <a href="/shanshen" className="header-list__item">
          山参
        </a>
        <a href="/hongshen" className="header-list__item">
          红参
        </a>
        <a href="/lihe" className="header-list__item">
          礼盒
        </a>
        <a href="/pifa" className="header-list__item">
          批发
        </a>
        <a href="/lurong" className="header-list__item">
          鹿茸
        </a>
        <a href="/hama" className="header-list__item">
          东北雪蛤蟆
        </a>
      </div>
    </div>
  );
};
export default Header;
